import {Fragment, useState, useEffect} from "react";
import Accordion from "react-bootstrap/Accordion";
import ProcessData from "./components/data/data_handler";
import {OrderRequest, initOrderRequest} from "./components/data/order/order_request";
import {        
    ItemSize,
    ItemProductLine,
    ItemXOPProperties,
    ItemProductType,    
    ItemWindowProperties,
    ItemGlass,    
    ItemGrids,
    ItemFrameType,
    ItemFrameColor,
    ItemScreen,
    ItemOption
} from "./components/static_controls/static_accordion_items";
import ViewPort from "./components/render/render_components/viewport/viewport";
import {RenderData, initRenderData} from "./components/render/render_components/render_data/render_data";
import {seedConfigValues} from "./components/render/render_components/render_data/config_values";
import {getConstrainCopy, getRenderDataCopy} from "./components/data/copy_data";
import {AddOrderControl} from "./components/static_controls/static_add_order";
import {OrderListControl} from "./components/static_controls/static_order_list";
import {OrderText} from "./components/static_controls/static_order_text";
import {OrderList, initOrderList} from "./components/data/order/order_list";
import {HeaderButtons} from "./components/static_controls/static_header_buttons";
import {ConstrainValues, initConstrainValues, seedConstrainValues} from "./components/constraints/constrain_value";
import {constrainActiveWindow} from "./components/constraints/functions_constrain";
import {SessionData} from "../session/session_data";

interface Props{
    sessionData: SessionData
}

const Configurator = ({sessionData}: Props) => {
        
    const [renderData, setRenderData] = useState<RenderData>(initRenderData());
    const [orderRequest, setOrderRequest] = useState<OrderRequest>(initOrderRequest);
    const [orderList, setOrderList] = useState<OrderList>(initOrderList);
    const [constrainValues, setConstrainValues] = useState<ConstrainValues>(initConstrainValues);
    const [locationID, setLocationID] = useState<string>("DEF001");

    //Seed the configurator data into the render data maps, by getting it from the server
    useEffect (() =>{
        const seedConfig = async () =>{
            const render= getRenderDataCopy(renderData);
            const constrain_values = getConstrainCopy(constrainValues);
            
            //Seed the config values from the databasee
            render.config_values = await seedConfigValues(render.config_values);            
            
            //Seed the constrain values from the config values
            seedConstrainValues(render.config_values, constrain_values);

            //****************************************************************************
            //These are just temporary settings to make development easier
            /*
            constrain_values.control.configurator.is_hidden = false;
            constrain_values.control.configurator.is_disabled = false;
            constrain_values.control.xop.is_disabled = false;
            constrain_values.control.xop.is_hidden = false;
            */
            //****************************************************************************
            
            //Set the constrain values and render data
            setConstrainValues(constrain_values);
            setRenderData(render);
        }
        seedConfig();
    }, []);

    //Monitor changes in active window id
    useEffect (() =>{
        if(renderData.active_fragment_id >=0){
            const render = getRenderDataCopy(renderData);
            const constrain = getConstrainCopy(constrainValues);

            constrainActiveWindow(render, constrain);

            setConstrainValues(constrain);
            setRenderData(render);
        }
        else{            
            //Clear the pricing; this catches a clear command executed
            //***** This probably should be in the clear command, but that requires passing a lot more junk around
            if(renderData.viewport.height === 0 && renderData.viewport.width === 0){
                setOrderRequest((orderRequest) =>({
                    ...orderRequest,
                    price: 0
                }));
            }
        }
    },[renderData.active_fragment_id])    

    const DataHandler = (id_item: string, id_object: string, form_object: string, object_value:string, key_id: number) =>{              
        ProcessData(
            id_item, 
            id_object, 
            form_object, 
            object_value, 
            key_id,
            orderRequest,                              
            setOrderRequest,
            renderData,
            setRenderData,
            constrainValues,
            setConstrainValues,
            locationID,
            sessionData
        );
    }     
    return (        
        <Fragment>      
            <div style={{maxWidth: "50%", margin: "0 auto"}}>                                
                <div className="mt-2 text-center">
                    <h1>Configurator</h1>
                </div>                                                          
            </div>
            <HeaderButtons DataHandler={DataHandler} locationID={locationID} setLocationID={setLocationID}/>            
            {!constrainValues.control.configurator.is_hidden && ( 
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-5" >  
                            <Accordion>                    
                                <ItemSize DataHandler={DataHandler} renderData={renderData} constrainValues={constrainValues} />
                                <ItemProductLine DataHandler={DataHandler} renderData={renderData} constrainValues={constrainValues} />
                                <ItemFrameType DataHandler={DataHandler} renderData={renderData} constrainValues={constrainValues} />
                                <ItemFrameColor DataHandler={DataHandler} renderData={renderData} constrainValues={constrainValues} />
                                {!constrainValues.control.xop.is_hidden && (
                                    <ItemXOPProperties DataHandler={DataHandler} renderData={renderData} constrainValues={constrainValues} />
                                )}
                                <ItemProductType DataHandler={DataHandler} renderData={renderData} constrainValues={constrainValues} />                        
                                {!constrainValues.control.window.is_hidden && (
                                    <ItemWindowProperties DataHandler={DataHandler} renderData={renderData} constrainValues={constrainValues} />                        
                                )}
                                <ItemGlass DataHandler={DataHandler} renderData={renderData} constrainValues={constrainValues} />                    
                                {!constrainValues.control.grid.is_hidden && (
                                    <ItemGrids DataHandler={DataHandler} renderData={renderData} constrainValues={constrainValues} />
                                )}
                                {!constrainValues.control.screen.is_hidden && (
                                    <ItemScreen DataHandler={DataHandler} renderData={renderData} constrainValues={constrainValues} />
                                )}
                                <ItemOption DataHandler={DataHandler} renderData={renderData} constrainValues={constrainValues} />
                            </Accordion> 
                        </div>
                        
                        <div className="col border d-flex align-items-center justify-content-center">
                            <ViewPort 
                                setRenderData={setRenderData} 
                                renderData={renderData}                                
                             />
                        </div>                    
                    </div>
                    <div className="row">
                        <div className="col-5"></div>
                        <div className="col">
                            <AddOrderControl 
                                renderData={renderData} 
                                orderRequest={orderRequest} 
                                orderList={orderList} 
                                setOrderList={setOrderList}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <OrderListControl orderList={orderList} setOrderList={setOrderList}/>
                        </div>
                        <div className="col">
                            <OrderText orderList={orderList} />
                        </div>
                    </div>
                </div>
            )}

        </Fragment>        
    );
};

export default Configurator;