import {initCredentials} from "./credentials"
import {ADDRESS_SERVER} from "../settings";

export const loginRequest = async(user: string, pass: string) =>{    
    const creds = initCredentials();
    creds.user = user;
    creds.pass = pass;
    
    const request_data = JSON.stringify(creds);
    
    try{
        const response = await fetch(ADDRESS_SERVER + "login", {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: request_data
        });

        const jsonData: boolean = await response.json();
        return jsonData;
    }
    catch (error: any){
        console.log(error.message);
    }    
}