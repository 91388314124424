import {RenderData} from "../render_data/render_data"
import {Segment} from "../render_data/enum_render";
import {WindowFragment} from "../window/window_fragment";

//Default text formats for information
const default_font = "25px Arial";
const default_fill = "rgba(125, 50, 185)";
const tempered_offset = 25;

//Display various information for windows
export const maskInfo = (renderData: RenderData, window: WindowFragment) =>{
    maskTempered(renderData, window);
    maskFrameType(renderData, window);
}

//Display information about the frame type
const maskFrameType = (renderData: RenderData, window: WindowFragment) =>{
    //Only display the information one time; on the first window in this case
    if(window.id === 0){        
        const x = renderData.viewport.width /2;
        const y = 25;
        const frame_string = renderData.data.frame_type.value;        
        
        displayText(renderData, x, y, "18px Arial", "black", frame_string);
        
    }
}

//Display information for tempered
const maskTempered = (renderData: RenderData, window: WindowFragment) =>{    
    //Iterate through all the panels
    window.panel.forEach(panel =>{
        //If the inner or outer window are tempered
        if(panel.data.glass.inner.tempered || panel.data.glass.outer.tempered){
            let temp_string = ""
            if(panel.data.glass.inner.tempered){
                temp_string += "#/";
            } 
            else{
                temp_string += "-/";
            }

            if(panel.data.glass.outer.tempered){
                temp_string += "#";
            }
            else{
                temp_string += "-";
            }
            
            //Find the center of the window
            const left = panel.model.line[Segment.LEFT].x1;
            const right = panel.model.line[Segment.RIGHT].x1;
            const top = panel.model.line[Segment.TOP].y1;
            const bottom = panel.model.line[Segment.BOTTOM].y1;

            const center_x = (left + right) /2;
            const center_y = (top + bottom) /2;

            displayText(renderData, center_x, center_y - tempered_offset, default_font, default_fill, temp_string);
        }
    })
}

//Display text information
const displayText = (
    renderData: RenderData,
    x: number,
    y: number,
    font: string,
    fill: string,
    text: string
) =>{
    const context = renderData.viewport.context;

    context!.save();
    context!.textAlign = "center";
    context!.textBaseline = "middle";
    context!.font = font;
    context!.fillStyle = fill;
    context!.translate(x, y);
    context!.fillText(text, 0 , 0);
    context!.restore();

}