import {setGridX, setGridY} from "../../render/render_components/viewport/functions_viewport";
import {RenderData} from "../../render/render_components/render_data/render_data";
import {PanelFragment} from "../../render/render_components/panel/panel_fragment";
import {WindowFragment} from "../../render/render_components/window/window_fragment";
import {constrainGrid} from "../../constraints/functions_constrain";
import {ConstrainValues} from "../../constraints/constrain_value";
import {clearPanelGrid} from "../../render/render_components/panel/functions_panel";

const WindowGrid = (
    id_object: string, 
    form_object: string, 
    object_value: string, 
    key_id: number,     
    renderData: RenderData,
    constrainValues: ConstrainValues    
) =>{
    
    const window = renderData.window[renderData.active_fragment_id];
    const panel = window.panel[window.active_panel_id];

    let bypass_apply_all = false;

    //Grid Type
    if(id_object === "group_grid_type"){                        
        panel.data.grid.grid_type.value = object_value;
        panel.data.grid.grid_type.id = key_id;        
    }

    //DVLs
    if(form_object === "dvl_width"){
        if(object_value){                    
            panel.data.grid.dvl_width = Number(object_value);
            if(renderData.active_fragment_id >=0){
                setGridX(renderData, Number(object_value), panel);
            }                                        
        }
        else{
            panel.data.grid.dvl_width = 0;       
        }        
    }
    if(form_object === "dvl_height"){
        if(object_value){
            panel.data.grid.dvl_height = Number(object_value);
            if(renderData.active_fragment_id >=0){
                setGridY(renderData, Number(object_value), panel);
            }                                     
        }
        else{
            panel.data.grid.dvl_height = 0;
        }
        
    }

    //Add the grid pattern to the panel
    if(id_object === "grid_pattern"){
        clearPanelGrid(renderData, panel);
        panel.data.grid.grid_pattern.id = key_id;
        panel.data.grid.grid_pattern.value = object_value;
        
        if(object_value === "Standard"){
            applyDefaultGridStandard(renderData, window, panel);
            if(window.panel.length === 3) bypass_apply_all = true;
        }
    }
    
    panel.data.grid.has_grid = hasGrid(panel);

    if(id_object === "apply_all"){
        if(object_value === "false"){
            renderData.all_window.apply_all_grid = false;
        }   
        else{
            renderData.all_window.apply_all_grid = true;
        }
    }
    applyAllValue(renderData, panel, bypass_apply_all);
    
    constrainGrid(renderData, object_value, constrainValues)
}

//Apply default grid patterns for standard grids
const applyDefaultGridStandard = (renderData: RenderData, window: WindowFragment, panel: PanelFragment) =>{
    const width = renderData.viewport.inch_width;
    const height = renderData.viewport.inch_height;

    //Calc the height of the DVL to be close to 12 inches
    const dvl_height = Math.round(height/12);
    
    let dvl_width =0;

    switch(window.panel.length){
        case 1:            
            if(width <= 60) dvl_width = 4;
            if(width > 60 && width <= 84) dvl_width = 6;
            if(width > 84) dvl_width = 8;

            panel.data.grid.dvl_width = dvl_width;
            panel.data.grid.dvl_height = dvl_height;
            setGridX(renderData, dvl_width, panel);
            setGridY(renderData, dvl_height, panel);
        break;
        case 2:
            if(width <= 60) dvl_width = 2;
            if(width > 60 && width <= 84) dvl_width = 3;
            if(width > 84) dvl_width = 4;

            panel.data.grid.dvl_width = dvl_width;
            panel.data.grid.dvl_height = dvl_height;
            setGridX(renderData, dvl_width, panel);
            setGridY(renderData, dvl_height, panel);
        break;
        case 3:
            defaultGrid3Panel(renderData, window, panel, dvl_height);
        break;
    }
}

//Set the default grids for a 3 panel window
const defaultGrid3Panel = (renderData: RenderData, window: WindowFragment, panel: PanelFragment, dvl_height: number) =>{
    if(renderData.all_window.apply_all_grid){
        //Iterate through each panel in the window and set the grid values
        window.panel.forEach(current_panel =>{            
            current_panel.data.grid.has_grid = panel.data.grid.has_grid;
            current_panel.data.grid.grid_type.id = panel.data.grid.grid_type.id;
            current_panel.data.grid.grid_type.value = panel.data.grid.grid_type.value;                                        
            current_panel.data.grid.grid_pattern.id = panel.data.grid.grid_pattern.id;
            current_panel.data.grid.grid_pattern.value = panel.data.grid.grid_pattern.value;
        })
        
        //Short hand for the 3 panel
        const panel_left = window.panel[0];
        const panel_center = window.panel[1];
        const panel_right = window.panel[2];
        const outer_dvl_width = 2;
        const center_dvl_width = 4;

        //Set the outer panels
        setGridX(renderData, outer_dvl_width, panel_left);
        setGridY(renderData, dvl_height, panel_left);
        setGridX(renderData, outer_dvl_width, panel_right);
        setGridY(renderData, dvl_height, panel_right);               

        //Set the inner panel        
        setGridX(renderData, center_dvl_width, panel_center);
        setGridY(renderData, dvl_height, panel_center);

        //Sete the grid values in the data
        panel_left.data.grid.dvl_width = outer_dvl_width;
        panel_left.data.grid.dvl_height = dvl_height;
        panel_center.data.grid.dvl_width = center_dvl_width;
        panel_center.data.grid.dvl_height = dvl_height;
        panel_right.data.grid.dvl_width = outer_dvl_width;
        panel_right.data.grid.dvl_height = dvl_height;
        panel_left.data.grid.has_grid = true;
        panel_center.data.grid.has_grid = true;
        panel_right.data.grid.has_grid = true;

    }
}

//Apply the grid value to all panels
const applyAllValue = (renderData: RenderData, panel: PanelFragment, bypass: boolean) =>{    
    if(renderData.all_window.apply_all_grid && !bypass){    
        //Iterate through all the windows
        renderData.window.forEach((window) =>{            
            switch(panel.data.grid.grid_pattern.value){
                case "No Grid":                    
                break;
                case "Diagram":
                    applyAll(window, panel);
                break;
                case "Sunburst":
                    
                break;
                case "Standard":
                    applyStandard(renderData, window, panel);
                break;
                case "Margin":
                break;                                
            }            
        })
    }
}

//Apply to all panels
const applyAll = (window: WindowFragment, panel: PanelFragment) =>{
    window.panel.forEach(current_panel =>{
        if(current_panel.id !== panel.id){
            current_panel.data.grid.grid_pattern.id = panel.data.grid.grid_pattern.id;
            current_panel.data.grid.grid_pattern.value = panel.data.grid.grid_pattern.value;
        }
    })
}

//Apply standard grids to all windows that can have a standard grid
const applyStandard = (renderData: RenderData, window: WindowFragment, panel: PanelFragment) =>{    
    switch(window.data.product_type.value){
        case "PW":
        case "XO":
        case "OX":
        case "XOX":
        case "XOO":
        case "OXO":
        case "OOX":
        case "OXXO":
        case "Single Hung":                        
            //Iterate through all the panels
            window.panel.forEach((current_panel) =>{
                if(current_panel.id !== panel.id){                     
                    current_panel.data.grid.dvl_width = panel.data.grid.dvl_width;
                    current_panel.data.grid.dvl_height = panel.data.grid.dvl_height;                    
                    current_panel.data.grid.has_grid = panel.data.grid.has_grid;
                    current_panel.data.grid.grid_type.id = panel.data.grid.grid_type.id;
                    current_panel.data.grid.grid_type.value = panel.data.grid.grid_type.value;                                        
                    current_panel.data.grid.grid_pattern.id = panel.data.grid.grid_pattern.id;
                    current_panel.data.grid.grid_pattern.value = panel.data.grid.grid_pattern.value;
                    setGridX(renderData, panel.mask_grid.grid_x, current_panel);
                    setGridY(renderData, panel.mask_grid.grid_y, current_panel);                    
                }
                panel.data.grid.has_grid = hasGrid(panel);
            })
        break;
    }
}

//Determine if a panel has a grid
const hasGrid = (panel: PanelFragment): boolean =>{
    
    if(panel.data.grid.grid_pattern.value !== "No Grid") return true;    
    
    return false;
}

export default WindowGrid;