import {ConfigValues, RecordData} from "../render/render_components/render_data/config_values";
import {Limit, initLimit} from "./limit";

//Value interface for configurator parameters
export interface ConstrainParameter{
    id: number,
    description: string, //Parameter description
    is_hidden: boolean, //If the parameter is hidden
    is_disabled: boolean, //If the parameter is disabled/greyed out
    is_removed: boolean, //If the parameter has been removed from the system, but who knows, they may add it back in.    
}

//Initialize configurator parameter
const initConstrainParameter = (): ConstrainParameter =>({
    id: -1,
    description: "",
    is_hidden: true,
    is_disabled: true,
    is_removed: false,    
})

export interface ConstrainType{
    parameter: Map<number, ConstrainParameter>; //A map containing all the constrain parameters
    string_id: Map<string, number>; //A map where the description is the key to the value id
}

export const initConstrainType = (): ConstrainType =>({
    parameter: new Map<number, ConstrainParameter>(),
    string_id: new Map<string, number>()
})

//Constraint properties for controls
export interface ConstrainControlProperty{
    is_disabled: boolean
    is_hidden: boolean    
}

//Initialize constraint control properties
const initConstrainControlProperty = (): ConstrainControlProperty =>({
    is_disabled: true,
    is_hidden: true,    
})

interface ConstrainControl{
    layout: string
    configurator: ConstrainControlProperty
    xop: ConstrainControlProperty
    xop_advanced: ConstrainControlProperty
    xop_top: ConstrainControlProperty
    xop_bottom: ConstrainControlProperty
    xop_left: ConstrainControlProperty
    xop_right: ConstrainControlProperty
    xop_width: ConstrainControlProperty
    xop_height: ConstrainControlProperty
    xop_split_horizontal: ConstrainControlProperty
    xop_split_vertical: ConstrainControlProperty
    window: ConstrainControlProperty
    window_width: ConstrainControlProperty
    window_height: ConstrainControlProperty
    window_open_direction: ConstrainControlProperty
    window_second_height: ConstrainControlProperty
    grid: ConstrainControlProperty
    grid_dvl: ConstrainControlProperty
    screen: ConstrainControlProperty
    size_callout: ConstrainControlProperty
}

const initConstrainControl = (): ConstrainControl =>({
    layout: "",
    configurator: initConstrainControlProperty(),
    xop: initConstrainControlProperty(),
    xop_advanced: initConstrainControlProperty(),
    xop_top: initConstrainControlProperty(),
    xop_bottom: initConstrainControlProperty(),
    xop_left: initConstrainControlProperty(),
    xop_right: initConstrainControlProperty(),
    xop_width: initConstrainControlProperty(),
    xop_height: initConstrainControlProperty(),
    xop_split_horizontal: initConstrainControlProperty(),
    xop_split_vertical: initConstrainControlProperty(),
    window: initConstrainControlProperty(),
    window_width: initConstrainControlProperty(),
    window_height: initConstrainControlProperty(),
    window_open_direction: initConstrainControlProperty(),
    window_second_height: initConstrainControlProperty(),
    grid: initConstrainControlProperty(),
    grid_dvl: initConstrainControlProperty(),
    screen: initConstrainControlProperty(),
    size_callout: initConstrainControlProperty()
})

//Interface for all the constrain values
export interface ConstrainValues{
    product_line: ConstrainType;
    frame_type: ConstrainType;
    frame_color: ConstrainType;
    product_type: ConstrainType;
    treatment: ConstrainType;
    glass: ConstrainType;
    grid_type: ConstrainType;
    grid_pattern: ConstrainType;
    screen: ConstrainType;
    option: ConstrainType;
    control: ConstrainControl;
    limit: Limit;
}

//Initialize all the constrain values
export const initConstrainValues = (): ConstrainValues =>({
    product_line: initConstrainType(),
    frame_type: initConstrainType(),
    product_type: initConstrainType(),
    frame_color: initConstrainType(),
    treatment: initConstrainType(),
    glass: initConstrainType(),
    grid_type: initConstrainType(),
    grid_pattern: initConstrainType(),
    screen: initConstrainType(),
    option: initConstrainType(),
    control: initConstrainControl(),
    limit: initLimit()  
})


//Hate to do it this way, but this is used to clarify between parameters with matcing names. IE "Bronze" frame and "Bronze" glass.
//We could consider putting it in the ConfigValues data structure somehow
enum ConfigType{
    PRODUCT_LINE = 0,
    FRAME_TYPE =1,
    FRAME_COLOR =2,
    PRODUCT_TYPE =3,
    TREATMENT =4,
    GLASS =5,
    GRID_TYPE =6,
    GRID_PATTERN =7,
    SCREEN =8,
    OPTION =9
}

//Seed the data from the configValues to the constrain values
export const seedConstrainValues = (configValue: ConfigValues, constrainValue: ConstrainValues) =>{
    
    //Seed the parameters of the constrain values from the config values
    seedParameter(configValue.product_line, constrainValue.product_line, ConfigType.PRODUCT_LINE);
    seedParameter(configValue.frame_type, constrainValue.frame_type, ConfigType.FRAME_TYPE);
    seedParameter(configValue.frame_color, constrainValue.frame_color, ConfigType.FRAME_COLOR);
    seedParameter(configValue.product_type, constrainValue.product_type, ConfigType.PRODUCT_TYPE);
    seedParameter(configValue.treatment, constrainValue.treatment, ConfigType.TREATMENT);
    seedParameter(configValue.glass, constrainValue.glass, ConfigType.GLASS);
    seedParameter(configValue.grid_type, constrainValue.grid_type, ConfigType.GRID_TYPE);
    seedParameter(configValue.grid_pattern, constrainValue.grid_pattern, ConfigType.GRID_PATTERN);
    seedParameter(configValue.screen, constrainValue.screen, ConfigType.SCREEN);
    seedParameter(configValue.option, constrainValue.option, ConfigType.OPTION);
}

//Seed parameters into a constrain map
const seedParameter = (configMap: Map<string, RecordData>, constrainType: ConstrainType, configType : ConfigType) =>{

    //Iterate through all the config map values and populate the constrain map values
    configMap.forEach(value =>{
        const parameter = initConstrainParameter();
        const string_id = constrainType.string_id;
        
        //Set the parameter values from config values
        parameter.id = value.id;
        parameter.description = value.description;
        parameter.is_disabled = false;
        parameter.is_hidden = false;        
        parameter.is_removed = seedRemoved(value.description, configType);

        if(parameter.is_removed){
            parameter.is_disabled = true;
            parameter.is_hidden = true;
        }

        //Set the name lookup value; maps the description to the parameter
        string_id.set(parameter.description, parameter.id);
        
        //Set the parameter into the constrain map
        constrainType.parameter.set(parameter.id, parameter);
    })
}

//Set parameters as being removed. This allows us to remove entries from the system, but still able to add them back
const seedRemoved = (description: string, configType: ConfigType): boolean =>{    
    
    switch (description){
        case "Bronze":
            if(configType === ConfigType.GLASS) return true;            
        break;
        case "Solar Grey":
        case "Solar Cool":
        case "I89":
        case "OXXO":
        case "Vent Limiter":
            return true;        
    }

    return false;
}