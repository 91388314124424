import {RenderData} from "../../render/render_components/render_data/render_data";
import {createTwoPanel, setPanelOpen} from "../../render/render_components/panel/functions_panel";
import {PanelType, OpenDirection} from "../../render/render_components/render_data/enum_render";
import {initDataPoint} from "../../render/render_components/render_data/render_data";

//Set any default settings based on the layout
export const defaultConfiguration = (renderData: RenderData) =>{
    switch(renderData.all_window.layout){
        case "Patio Door":
            defaultPatioDoor(renderData);
        break;
        case "Window":
            defaultWindow(renderData);
        break;
        case "XOP":
        case "Stack/Mull":
            renderData.active_fragment_id =0;
            const window = renderData.window[0];
            window.active_panel_id =0;    
        break;
    }
}

//Set the default configurations for a patio door
const defaultPatioDoor = (renderData: RenderData) =>{
    //Set the active window and panel to the window that was just created        
    renderData.active_fragment_id =0;
    const window = renderData.window[0];
    window.active_panel_id =0;
    
    //For patio doors, change the inital panel to OX instead of PW    
    const record = renderData.config_values.product_type.get("OX")!;
    window.data.product_type.id = record.id;
    window.data.product_type.value = record.description;
    
    //Create the panels and set the open direction
    createTwoPanel(renderData, window);            
    setPanelOpen(window, PanelType.RIGHT_OF_TWO, OpenDirection.LEFT);            

    //Iterate through the panels
    window.panel.forEach((panel) =>{
        panel.data.glass.inner.tempered = true;
        panel.data.glass.outer.tempered = true;

        panel.data.has_screen = true;            
    })

    //If it's a special size
    if(!renderData.data.is_callout){
        renderData.window.forEach((window) =>{
            window.panel.forEach((panel) =>{
                const key_name = "Special Sqrft";                   
                const value = renderData.config_values.option.get(key_name);
                
                const data_point = initDataPoint("");
                data_point.id = value!.id;
                panel.data.option.set(key_name, data_point);
                
            })
        })
    }
    
}

//Set the default configurations for a window
const defaultWindow = (renderData: RenderData) =>{
    //Set the active window and panel to the window that was just created        
    renderData.active_fragment_id =0;
    const window = renderData.window[0];
    window.active_panel_id =0;
    
    //Get the product record values
    const product_record = renderData.config_values.product_type.get("XO")!;
    window.data.product_type.id = product_record.id;
    window.data.product_type.value = product_record.description;
        
    //Create the panels and set the open directions
    createTwoPanel(renderData, window);            
    setPanelOpen(window, PanelType.LEFT_OF_TWO, OpenDirection.RIGHT);
    
    //Get the glass types
    //const clear_glass = renderData.config_values.glass.get("Clear")!;
    //const hp_glass = renderData.config_values.glass.get("HP Low-E")!;
    
    //Iterate through the panels
    window.panel.forEach((panel) =>{
        //Set the inner and outer glass to clear/hp low e
        /*
        panel.data.glass.inner.id = clear_glass.id;
        panel.data.glass.inner.value = clear_glass.description;
        panel.data.glass.outer.id = hp_glass.id;
        panel.data.glass.outer.value = hp_glass.description;
        */

        panel.data.has_screen = true;            
    })
}

//Calculate the size of a callout in net frame
export const calloutToNet = (callout: number, renderData: RenderData) =>{
    //Calculate the size of the window in inches, from callout size             
    renderData.data.callout = callout;
    
    //Divide the callout into values; assume anyting over 10000 is part of the x value
    let current = callout;
    const x1 = Math.floor(current / 1000);                       
    current -= x1 * 1000;          
    
    const x2 = Math.floor(current / 100);
    current -= x2 * 100;

    const y1 = Math.floor(current / 10);
    current -= y1 * 10;

    const y2 = current;

    const width = (x1 * 12) + x2;
    const height = (y1 * 12) + y2;

    return {width, height}
}