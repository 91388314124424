//*******************************************
//This needs to match between server and client
//*******************************************

export interface Credentials{
    user: string,
    pass: string
}

export const initCredentials = (): Credentials =>({
    user: "",
    pass: ""
})