import {RenderData} from "../../render/render_components/render_data/render_data";
import {setViewPortSize} from "../../render/render_components/viewport/functions_viewport";
import {defaultConfiguration} from "./functions_data";
import {calloutToNet} from "./functions_data";
import {resetFrameColor} from "./functions_reset_data";
import {FRAME_ADJUST} from "../../../../settings";


const FrameType = (object_value: string, key_id: number, renderData: RenderData) =>{
    
    resetFrameColor(renderData);
    
    if(key_id === -1 && renderData.data.frame_type.id >0){
        renderData.data.frame_type.id =0;
        renderData.data.frame_type.value ="";
    }
    else{
        renderData.data.frame_type.value = object_value;
        renderData.data.frame_type.id = key_id;

        if(renderData.data.is_callout){
            //Set the size of the overall net based on the callout
            renderData.viewport.inch_width = calloutToNet(renderData.data.callout, renderData).width;
            renderData.viewport.inch_height = calloutToNet(renderData.data.callout, renderData).height;                

            //Adjust the frame if it hasn't been already
            if(!renderData.data.frame_adjusted){
                switch(object_value){               
                    case "Flush Fin":
                    case "Nail On":
                    case "NuWall":
                        //Adjust the overall size based on specific frames                    
                        renderData.viewport.inch_width -= FRAME_ADJUST;
                        renderData.viewport.inch_height -= FRAME_ADJUST;
                    break;
                }
            }

            //Recreate the window
            setViewPortSize(renderData);

            defaultConfiguration(renderData);
        }
    }    
}

export default FrameType;