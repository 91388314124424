import {OrderList} from "../data/order/order_list";
import React, {useState, Fragment, useRef} from "react";
import {createRoot} from "react-dom/client";
import Form from "react-bootstrap/Form";
import {BUTTON_TYPE} from "../../../settings";
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";

//Submit an order into the system
export const submitOrder = (orderList: OrderList) =>{
    //Window dimensions
    const width = 600;
    const height = 400;

    //Calculate window position to center it
    const left = (window.outerWidth - width) / 2 + window.screenX;
    const top = (window.outerHeight - height) / 2 + window.screenY;
    
    const loginWindow = window.open(
        "", 
        "", 
        `width=${width},
        height=${height},
        left=${left},
        top=${top}`
    );
    if(loginWindow){

        // Inject Bootstrap CSS
        const link = loginWindow.document.createElement("link");
        link.rel = "stylesheet";
        link.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css";
        loginWindow.document.head.appendChild(link);

        // Inject Popper.js (required by Bootstrap for some components like tooltips)
        const popperScript = loginWindow.document.createElement("script");
        popperScript.src = "https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js";
        popperScript.integrity = "sha384-I7E8VVD/ismYTF4hNIPjVp/Zjvgyol6VFvRkX/vR+Vc4jQkC+hVqc2pM8ODewa9r";
        popperScript.crossOrigin = "anonymous";
        loginWindow.document.body.appendChild(popperScript);

        // Inject Bootstrap JS
        const bootstrapScript = loginWindow.document.createElement("script");
        bootstrapScript.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js";
        bootstrapScript.integrity = "sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL";
        bootstrapScript.crossOrigin = "anonymous";
        loginWindow.document.body.appendChild(bootstrapScript);

        // Inject CSS to center content
        const style = loginWindow.document.createElement("style");
        style.innerHTML = `
        body, html {
            height: 100%;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f8f9fa; /* Optional: Background color */
        }
        .container {
            width: 100%;
            max-width: 400px; /* Optional: Limit the width of the container */
        }
        `;
        loginWindow.document.head.appendChild(style);
        
        //Inject container for Reach rendering
        const container = loginWindow.document.createElement('div');
        loginWindow.document.body.appendChild(container);

        //Render React context
        createRoot(container).render(
            <React.StrictMode>
                <LoginForm orderList={orderList} loginWindow={loginWindow}/>
            </React.StrictMode>
        );
    }

}

const INPUT_WIDTH = "210px";
const COLUMN_1 = "col-3";
const COLUMN_2 = "col-5";

interface PropForm{
    orderList: OrderList
    loginWindow: Window
}

const LoginForm = ({
    orderList,
    loginWindow
}: PropForm) =>{

    //Show an error when logging in
    const [showError, setShowError] = useState(false);
    
    //State control of showing or hiding the modal
    const [showModal, setShowModal] = useState(false);

    //User name and password values
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    //Reference to the password text box
    const passwordRef = useRef<HTMLInputElement>(null);

    //State to dynamically set the modal content
    const [modalContent, setModalContent] = useState({
        title: "",
        body: ""
    })
    
    //Function for handling closing the modal
    const handleCloseModal = () =>{
        setShowModal(false);
    }

    //Handle the sign in button being clicked
    const handleButtonClick = () =>{
        submitLogin();
    }

    //Handle when the enter key is pressed in a text box
    const handleKeyDown = (id: string, event: React.KeyboardEvent<HTMLElement>) =>{                        
        if(event.key === 'Enter'){
            switch(id){
                case "user":
                    passwordRef.current?.focus();
                break;
                case "pass":
                    submitLogin();
                break;
            }
        }
    }

    //Process the text value of a text box
    const processTextValue = (id: string, value: string) =>{
        //Set user name and password values
        switch(id){
            case "user":
                setUserName(value);
            break;
            case "pass":
                setPassword(value)
            break;
        }
    }

    const submitLogin = () =>{       
        //******* Test for checking username and password
        //******* we may have to modify the case of the user name
        if(username === "abc" && password === "123"){
            setModalContent({
                title: "Order Submitted",
                body: "Order successfully submitted."
            });
            setShowModal(true);
            
    
            loginWindow.close();
        }
        else{
            setShowError(true);
        }        
    }

    return (
        <Fragment>
            <h2 className="mb-4">
                Enter Spruce Credentials
            </h2>            
            <Form>
                <div className="container">                
                    <div className="row mb-1 align-items-center">
                        <div className={COLUMN_1}>
                            <Form.Label>User</Form.Label>
                        </div>
                        <div className={COLUMN_2}>
                            <Form.Control
                                id="user"
                                type="text"
                                style={{width: INPUT_WIDTH}}                                
                                onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event)}
                                onChange={(event) => processTextValue(event.target.id, event.target.value)}
                            />
                        </div>                        
                    </div>
                    <div className="row mb-1 align-items-center">
                        <div className={COLUMN_1}>
                            <Form.Label>Password</Form.Label>
                        </div>
                        <div className={COLUMN_2}>
                            <Form.Control
                                id="pass"
                                type="password"
                                style={{width: INPUT_WIDTH}}                                
                                onChange={(event) => processTextValue(event.target.id, event.target.value)}
                                onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event)}                                
                                ref={passwordRef}
                            />
                        </div>                                                
                    </div>
                    <div className="row mt-2">
                        <div className="col text-end">
                            <Button 
                                variant={BUTTON_TYPE}
                                onClick={handleButtonClick}
                                className="me-2"
                            >
                                Sign In
                            </Button>
                        </div>
                    </div>
                    {showError && (
                        <div className="row mt-2">
                            <div className="col">
                                <Form.Label
                                    style={{color: "rgba(200,0,0,1"}}
                                >
                                    Invalid User or Password.
                                </Form.Label>
                            </div>
                        </div>
                    )}                    
                </div>
            </Form>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalContent.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalContent.body}</Modal.Body>
                <Modal.Footer>
                    <Button variant={BUTTON_TYPE} onClick={handleCloseModal}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
}