import React, {useState, Fragment, useRef} from "react";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import {BUTTON_TYPE} from "../settings";
import {SessionData, initSessionData} from "./session_data";
import {loginRequest} from "./login_request";

const INPUT_WIDTH = "210px";
const COLUMN_1 = "col-3";
const COLUMN_2 = "col-5";

interface ModalProps {    
    //handleLogin: (username: string, password: string) => void;
    handleLogin: () => void;
    setSessionData: React.Dispatch<React.SetStateAction<SessionData>>
}

export const LoginModal: React.FC<ModalProps> = ({    
    handleLogin,
    setSessionData
}) =>{
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [showError, setShowError] = useState(false);

    //Handle a user and password being submitted
    const handleSubmit = async (event: React.FormEvent) =>{
        event.preventDefault();

        const valid_login = await loginRequest(username, password);       
        
        if(valid_login){     
            const sessionData = initSessionData();
            sessionData.user = username;
            sessionData.pass = password;
            sessionData.valid_session = true;
            setSessionData(sessionData);
            handleLogin();
        }
        else{
            setShowError(true);
        }
    }

    /*
    const byPassLogin = () =>{
        const lcase_user = "wdepot";
        const sessionData = initSessionData();
        sessionData.user = lcase_user;
        sessionData.pass = "Wdepot";
        sessionData.valid_session = true;
        setSessionData(sessionData);
        handleLogin();
    } 
    */   

    //Handle a key down event
    const handleKeyDown = (id: string, event: React.KeyboardEvent<HTMLElement>) =>{
        if(id === "pass"){
            if(event.key === 'Enter'){
                event.preventDefault();
                handleSubmit(event);
            }            
        }
    }
    
    return (
        <div className="modal-overlay d-flex justify-content-center align-items-center vh-100">            
            <Form>                
                <h4 className="text-center">Enter User and Password</h4>                
                <div className="container">                
                    <div className="row mb-1 align-items-center">
                        <div className={COLUMN_1}>
                            <Form.Label>User</Form.Label>
                        </div>
                        <div className={COLUMN_2}>
                            <Form.Control
                                id="user"
                                type="text"
                                style={{width: INPUT_WIDTH}}
                                placeholder="Username"
                                value={username}
                                onChange={(event) => setUserName(event.target.value)}                                                                
                            />
                        </div>                        
                    </div>
                    <div className="row mb-1 align-items-center">
                        <div className={COLUMN_1}>
                            <Form.Label>Password</Form.Label>
                        </div>
                        <div className={COLUMN_2}>
                            <Form.Control
                                id="pass"
                                type="password"
                                style={{width: INPUT_WIDTH}}
                                placeholder="Password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event)}                                                                
                            />
                        </div>                                                
                    </div>
                    <div className="row mt-2">
                        <div className="col text-end">
                            <Button 
                                variant={BUTTON_TYPE}
                                onClick={handleSubmit}
                                className="me-2"
                            >
                                Sign In
                            </Button>
                        </div>
                        {/*
                        <div className="col text-end">
                            <Button 
                                variant={BUTTON_TYPE}
                                onClick={byPassLogin}
                                className="me-2"
                            >
                                Bypass Login
                            </Button>
                        </div>
                        */}
                    </div>
                    
                    {showError && (
                        <div className="row mt-2">
                            <div className="col">
                                <Form.Label
                                    style={{color: "rgba(200,0,0,1"}}
                                >
                                    Invalid User or Password.
                                </Form.Label>
                            </div>
                        </div>
                    )}                    
                </div>
            </Form>

        </div>
    );    
};
