import React from "react";
import {ItemID} from "../static_controls/static_accordion_items";
import {RenderData} from "../render/render_components/render_data/render_data";
import WindowSize from "./data_functions/window_size";
import XOPProperties from "./data_functions/xop_properties";
import ProductLine from "./data_functions/product_line";
import WindowProperties from "./data_functions/window_properties";
import ProductType from "./data_functions/product_type";
import Glass from "./data_functions/glass";
import WindowGrid from "./data_functions/window_grid";
import FrameType from "./data_functions/frame_type";
import WindowFrameColor from "./data_functions/frame_color";
import Screen from "./data_functions/screen";
import Option from "./data_functions/option";
import {getConstrainCopy, getRenderDataCopy} from "./copy_data";
import {OrderRequest, initOrderRequest} from "./order/order_request";
import {setOrderPrice, packOrderRequest} from "./order/functions_request";
import {handleHeaderButton} from "./data_functions/header_button";
import {ConstrainValues} from "../constraints/constrain_value";
import {SessionData} from "../../../session/session_data";

const ProcessData = async (
    id_item: string, //ID of from the Accordion Item
    id_object: string, //ID of a specific object in an Accordion Item
    form_object: string, //The name of a form object in an Accordion Item
    object_value: string, //Value of the control in an Accordion Item
    key_id: number, //Primary key value of the record    
    orderRequest: OrderRequest, //Order request data
    setOrderRequest: React.Dispatch<React.SetStateAction<OrderRequest>>, //Setter for order request data
    renderData: RenderData, //Data for rendering to the viewport.
    setRenderData: React.Dispatch<React.SetStateAction<RenderData>>, //Setter for viewport render data
    constrainValues: ConstrainValues, //Object for constraining values
    setConstrainValues: React.Dispatch<React.SetStateAction<ConstrainValues>>, //Setter for constrain values
    location_id: string, //The location ID value for looking up factors, based on location
    sessionData: SessionData //The session information
) =>{   
    
    const render = getRenderDataCopy(renderData);    
    const constrain = getConstrainCopy(constrainValues);

    //Change corresponding data on the structure, according to the accordion item it came from
    switch(id_item){        
        case "header_button":            
           handleHeaderButton(form_object, render, constrain);
        break;
        case ItemID.WINDOW_SIZE:                                
            await WindowSize(form_object, object_value, render, constrain);
        break;
        case ItemID.XOPPROPERTIES:                        
            XOPProperties(id_object, object_value, render, constrain);
        break;
        case ItemID.FRAME_TYPE:
            FrameType(object_value, key_id, render);
        break;
        case ItemID.FRAME_COLOR:
            WindowFrameColor(object_value, key_id, render, constrain);
        break;          
        case ItemID.PRODUCT_LINE:
            ProductLine(object_value, key_id, render, constrain);
        break;
        case ItemID.PRODUCT_TYPE:
            if(render.active_fragment_id >=0){
                ProductType(object_value, key_id, render, constrain);
            }
        break;
        case ItemID.WINDOW_PROPERTIES:
            if(render.active_fragment_id >=0){
                WindowProperties(id_object, object_value, key_id, render, constrain);
            }
        break;        
        case ItemID.GLASS:                
            if(render.active_fragment_id >=0){
                Glass(id_object, object_value, key_id, render);
            }
        break;            
        case ItemID.WINDOW_GRID:                
            if(render.active_fragment_id >=0){
                WindowGrid(id_object, form_object, object_value, key_id, render, constrain);
            }
        break;        
        case ItemID.SCREEN:
            if(render.active_fragment_id >=0){
                Screen(id_object, object_value, render);
            }
        break;
        case ItemID.OPTION:
            if(render.active_fragment_id >=0){
                Option(form_object, object_value, key_id, render);
            }
        break;     
    }    

    //I'm not sure if we want to reset every time, or update; both are valid with the packOrderRequest function
    //Below is the copy function for order requests, if we decide to switch
    //const order = getOrderRequestCopy(orderRequest);
    const order = initOrderRequest();    
    
    //Pack data from the renderData object into the order request object
    packOrderRequest(render, order, location_id, sessionData);    

    //Set objects
    const price = await setOrderPrice(setOrderRequest, order)    
    if(price === 0){        
        render.error.config.display = true;        
    }
    else{
        render.error.config.display = false;
    }    
    
    setConstrainValues(constrain);    
    setRenderData(render);
}

export default ProcessData;