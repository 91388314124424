import {RenderData} from "../../render/render_components/render_data/render_data";
import {OrderRequest} from "./order_request";
import {initOrderDetail, initDetailWindow, initDetailPanel, OrderDetail} from "./order_detail";
import {Segment} from "../../render/render_components/render_data/enum_render";
import {OpenDirection, OpenDirectionName} from "../../render/render_components/render_data/enum_render";
import {getOrderString} from "./functions_detail_display"; 

//Pack the order detail from the render data object and the order request price
export const packOrderDetail = (renderData: RenderData, orderRequest: OrderRequest): OrderDetail =>{
    const orderDetail = initOrderDetail();      

    //Save the canvas image into the order detail
    const context = renderData.viewport.context;
    orderDetail.img_canvas = saveCanvasImage(context!);
    orderDetail.img_width = renderData.viewport.width;
    orderDetail.img_height = renderData.viewport.height;

    //Set the initial order quantity count
    orderDetail.quantity =1;

    //Set the price
    orderDetail.price = orderRequest.price;

    //Set the values which apply to the net window
    orderDetail.layout = renderData.all_window.layout;
    orderDetail.product_line = renderData.data.product_line.value;
    orderDetail.frame_type = renderData.data.frame_type.value;
    orderDetail.frame_color = renderData.data.frame_color.value;
    //If it's a callout
    if(renderData.data.is_callout){
        orderDetail.is_callout = true;
        orderDetail.callout = renderData.data.callout;
    }    
    orderDetail.width = renderData.viewport.inch_width;
    orderDetail.height = renderData.viewport.inch_height;    
    
    //Get a sorted list of indices, according to window position (top - bottom, left - right)
    const index_list = sortWindows(renderData);

    //Iterate through all the windows in the render data
    //renderData.window.forEach(window =>{
    index_list.forEach(index =>{
        const window = renderData.window[index];
        
        const order_window = initDetailWindow();

        //Add window information to the order window
        order_window.width = window.model_inch.width;
        order_window.height = window.model_inch.height;
        order_window.second_height = window.model_inch.second_height;
        order_window.product_type = window.data.product_type.value;
        
        //If the size of a panel was changed in the window, display it in the order details
        if(window.data.panel_size_changed){
            order_window.display_panel_size = true;
        }

        //Set the upper left corner location of the window
        order_window.upper_left_x = window.model_inch.left;
        order_window.upper_left_y = renderData.viewport.inch_height - window.model_inch.top;

        //Iterate through all the panels in the window
        window.panel.forEach(panel =>{
            const order_panel = initDetailPanel();
            
            //Set the values of the panel
            order_panel.width = panel.model_inch.display_width;
            order_panel.height = panel.model_inch.display_height;
            
            //Set the open direction if one exists
            if(panel.mask_open.direction !== OpenDirection.NONE){
                order_panel.open_direction = OpenDirectionName[panel.mask_open.direction];
            }
            
            //Set the glass values
            order_panel.outer_glass.glass = panel.data.glass.outer.value;
            order_panel.outer_glass.tempered = panel.data.glass.outer.tempered;
            order_panel.inner_glass.glass = panel.data.glass.inner.value;
            order_panel.inner_glass.tempered = panel.data.glass.inner.tempered;

            //Set the grid values                        
            order_panel.grid.has_grid = panel.data.grid.has_grid;
            order_panel.grid.grid_type = panel.data.grid.grid_type.value;
            order_panel.grid.grid_pattern = panel.data.grid.grid_pattern.value;
            order_panel.grid.dvl_width = panel.data.grid.dvl_width;
            order_panel.grid.dvl_height = panel.data.grid.dvl_height;            

            //Set Screen
            order_panel.has_screen = panel.data.has_screen;

            //Iterate through all the options in the panel fragment
            panel.data.option.forEach(option =>{
                //Get the option value string and add it to the panel option array
                order_panel.option[order_panel.option.length] = option.value;
            })

            //Set the order panel into the order window
            order_window.panel[order_window.panel.length] = order_panel;
        })

        //Set the order window into the array
        orderDetail.window[orderDetail.window.length] = order_window;
    })

    //Create a string array of the order details
    const order_string: string[] = getOrderString(orderDetail);
    orderDetail.order_string = order_string;

    return orderDetail;

}

//Sort the windows by top to bottom and left to right and return a list of indices in order
const sortWindows = (renderData: RenderData): number[] =>{

    let window_list: number[] = [];
    let height_list: number[] = [];
    let height_map: Map<number, number[]> = new Map();
    //Get a list of all the top values of the windows
    renderData.window.forEach(window =>{
        const top = window.model.line[Segment.TOP].y1;

        //If the top value isn't already in the list
        if(!height_list.includes(top)){
            height_list[height_list.length] = top;

            let index_list: number[] = [];
            index_list[0] = window.id;
            height_map.set(top, index_list);
        }
        else{
            const index_list = height_map.get(top)!;
            index_list[index_list.length] = window.id;
        }
    })

    //Sort the list of heights
    height_list.sort();    

    //Iterate through sorted heights, put their lefts into a list, sort and then add the index to the window list    
    height_list.forEach(height =>{       
        
        const index_list = height_map.get(height)!;
        
        //List of left values
        let left_list: number[] = [];
        
        //Iterate through all the indexes stored in the list
        index_list.forEach(index =>{
            const window = renderData.window[index];

            left_list[left_list.length] = window.model.line[Segment.LEFT].x1;
        })

        left_list.sort(); //Sort the left list

        //Iterate through the 
        left_list.forEach(left =>{
            
            //Iterate through windows in the index list to find the left value it matches, then add it to the overall list
            index_list.forEach(index =>{
                const window = renderData.window[index];

                if(window.model.line[Segment.LEFT].x1 === left){
                    window_list[window_list.length] = window.id;
                }
            })
        })        
    })

    return window_list;
}

//Save the image from a canvas into a data object
const saveCanvasImage = (context: CanvasRenderingContext2D): string =>{
    const canvas = context.canvas;
    if(!canvas) {
        console.error("No canvas found in the context.");
        return "";
    }
    return canvas.toDataURL("image/png");
} 

