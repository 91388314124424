import ControlCanvas from "./control_canvas";
import {RenderData} from "../render_data/render_data";
import {Fragment} from "react";

interface ViewPortProps{
    setRenderData: React.Dispatch<React.SetStateAction<RenderData>>
    renderData: RenderData    
}

const ViewPort = ({
    setRenderData, 
    renderData,    
}: ViewPortProps) =>{
    return (        
        <Fragment>                      
            {renderData.viewport.valid_size && (
                <ControlCanvas 
                    setRenderData={setRenderData}
                    renderData={renderData}                    
                />
            )}
        </Fragment>
    );
}

export default ViewPort;