import {Fragment, useState, useEffect} from "react"
import Form from "react-bootstrap/Form";
import {RenderData} from "../render/render_components/render_data/render_data";
import {OpenDirection, Segment} from "../render/render_components/render_data/enum_render";
import {ConstrainValues} from "../constraints/constrain_value";

interface Props {
    id_item: string;
    DataHandler:(id_item: string, id_object: string, form_object: string, object_value: string, key_id: number) => void;
    renderData: RenderData;
    constrainValues: ConstrainValues
}   

export const WindowProperties = ({
    id_item,
    DataHandler,
    renderData,
    constrainValues
}:Props) =>{
    const INPUT_MAX_WIDTH = "100px";
    const COL_SIZE_LABEL = "col-1";
    const COL_SIZE_TEXT = "col-2";
    const ROW_MARGIN = "row mb-1";

    const [inputWidth, setInputWidth] = useState("");
    const [inputHeight, setInputHeight] = useState("");
    const [selectDirection, setSelectDirection] = useState(OpenDirection.NONE);
    const [filterDirection, setFilterDirection] = useState<Map<OpenDirection,string>>(new Map());
    const [inputSecondHeight, setInputSecondHeight] = useState("");

    //Handle a text control losing focus
    const handleBlur = (id: string, value: string) =>{
        processTextValue(id, value);
    }
    //Handle a key down event on a text control
    const handleKeyDown = (id: string, value: string, event: React.KeyboardEvent<HTMLElement>) =>{        
        if(event.key === 'Enter'){
            processTextValue(id, value);
        }
    }

    //Process a text value if it is valid, otherwise revert it
    const processTextValue = (id: string, value: string) =>{               
        if(renderData.active_fragment_id >=0){
            if(Number(value) >0){
                DataHandler(id_item, id, "", value, -1);
            }
            else{            
                populateTextFromActiveWindow();   
            }
        }
    }

    const handleTextChange = (id: string, value: string) =>{
        const value_number = Number(value);           
        
        if(renderData.active_fragment_id <0){
            return;
        }

        //Check if it's a valid entry
        if(!isNaN(value_number)){
            let valid_value = true;            

            const window = renderData.window[renderData.active_fragment_id];
            const panel = window.panel[window.active_panel_id];

            //Can't adjust the width if the panel is the same width as the window
            if(id === "input_width"){
                if(panel.model_inch.display_width === window.model_inch.width){                    
                    valid_value = false;                
                }
            }
            
            //Can't adjust the height if the panel is the same height as the window
            if(id === "input_height"){
                if(panel.model_inch.display_height === window.model_inch.height){
                    valid_value = false;
                }
            }           
            
            //Update the input text with the value that was entered; allow for blanks                
            if(valid_value || value === ""){
                switch(id){
                    case "input_width":
                        setInputWidth(value);
                    break;
                    case "input_height":
                        setInputHeight(value);
                    break;
                    case "input_second_height":
                        setInputSecondHeight(value);
                    break;
                }
            }
        }
    }

    //Handle direction changes
    const handleDirection = (key_id: number) =>{
        DataHandler(id_item, "change_direction", "", "", key_id)
    }

    const populateTextFromActiveWindow = () =>{
        const window = renderData.window[renderData.active_fragment_id];
        const panel = window.panel[window.active_panel_id];

        //Set the text box values to the panel values
        setInputWidth(String(panel.model_inch.display_width));
        setInputHeight(String(panel.model_inch.display_height));
        
        //If the window has a second height
        if(window.model_inch.second_height >0){
            //Invert the value to flip the second height to bottom up
            const invert_secondary = window.model_inch.height - window.model_inch.second_height;            
            setInputSecondHeight(String(invert_secondary));            
        }            
        
        //Set the possible directions for a panel to open
        let direction = new Map<OpenDirection, string>();
        direction.set(OpenDirection.NONE, "None");
        if(panel.model.line[Segment.TOP].y1 !== window.mask_window.top){
            direction.set(OpenDirection.UP, "Up");
        }
        if(panel.model.line[Segment.BOTTOM].y1 !== window.mask_window.bottom){
            direction.set(OpenDirection.DOWN, "Down");    
        }
        if(panel.model.line[Segment.LEFT].x1 !== window.mask_window.left){
            direction.set(OpenDirection.LEFT, "Left");
        }
        if(panel.model.line[Segment.RIGHT].x1 !== window.mask_window.right){
            direction.set(OpenDirection.RIGHT, "Right");
        }
        setFilterDirection(direction);
        
        //Set the current open direction for a panel
        setSelectDirection(panel.mask_open.direction);
    }

    //If the selected panel changes, update the control values
    useEffect(() =>{
        if(renderData.active_fragment_id >=0){
            populateTextFromActiveWindow();
        }
        else{
            setInputWidth("");
            setInputHeight("");
            setInputSecondHeight("");
            
            setFilterDirection(new Map());            
        }
    }, [renderData])
    
    return (
        <Fragment>
            <Form className="mt-3">
                <div className="container">                    
                    <Form.Label>Panel Dimensions</Form.Label>
                    {!constrainValues.control.window_width.is_hidden && (
                        <div className={ROW_MARGIN}>                        
                            <div className={COL_SIZE_LABEL + " d-flex align-items-center"}>
                                <Form.Label>Width</Form.Label>                      
                            </div>
                            <div className={COL_SIZE_TEXT}>
                                <Form.Control 
                                    id="input_width"
                                    type="text"
                                    value={inputWidth}
                                    style={{width: INPUT_MAX_WIDTH}}                                
                                    onChange={(event) => handleTextChange(event.target.id, event.target.value)}
                                    disabled={constrainValues.control.window_width.is_disabled}
                                    onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)}
                                />
                            </div>
                        </div>
                    )}

                    {!constrainValues.control.window_height.is_hidden && (
                        <div className={ROW_MARGIN}>                        
                            <div className={COL_SIZE_LABEL + " d-flex align-items-center"}>
                                <Form.Label>Height</Form.Label>                      
                            </div>
                            <div className={COL_SIZE_TEXT}>
                                <Form.Control 
                                    id="input_height"
                                    type="text"
                                    value={inputHeight}
                                    style={{width: INPUT_MAX_WIDTH}}
                                    onChange={(event) => handleTextChange(event.target.id, event.target.value)}                                
                                    disabled={constrainValues.control.window_height.is_disabled}
                                    onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)}
                                />
                            </div>
                        </div>
                    )}
                    {!constrainValues.control.window_open_direction.is_hidden && (
                        <div className="mt-3">
                            <Form.Label>Secondary Height</Form.Label>
                            <Form.Control 
                                id="input_second_height"
                                type="text"
                                value={inputSecondHeight}
                                style={{width: INPUT_MAX_WIDTH}}
                                disabled={constrainValues.control.window_second_height.is_disabled}                                
                                onChange={(event) => handleTextChange(event.target.id, event.target.value)}
                                onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                                onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)}                                
                            />
                        </div>
                    )}
                </div>
            </Form>
        </Fragment>
    );
}

export default WindowProperties;


// This code enables setting the open direction for windows.
/*
{!constrainValues.control.window_open_direction.is_hidden && (
    <div className="mt-3">
        <Form.Label>Open Direction</Form.Label>                                                              
        <div style={{maxWidth: "160px"}}>
            <select
                className="form-select"
                value={selectDirection}
                disabled={constrainValues.control.window_open_direction.is_disabled}
                onChange={(event) =>{
                    const selectedOption = event.target.options[event.target.selectedIndex];
                    const key_id = Number(selectedOption.value);

                    handleDirection(key_id);
                }}
            >
                {Array.from(filterDirection.entries()).map(([key, direction]) => (
                    <option 
                        key={key} 
                        value={key}
                        hidden={false}                                            
                    >
                        {direction}
                    </option>
                ))}
            </select>            
        </div>
    </div>
)}


*/