
export interface SessionData{
    user: string,
    pass: string,
    valid_session: boolean
}

export const initSessionData = (): SessionData =>({
    user: "",
    pass: "",
    valid_session: false
})