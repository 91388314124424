//___________________________________________________________________________________________________________________________________________________________________________________
//  Front-End Entry Point
//
//  This is essentially the entry point for the front end.
//___________________________________________________________________________________________________________________________________________________________________________________


import {Fragment, useState} from "react";
import "./App.css";
import Configurator from "./components/configurator/configurator";
import {SessionData, initSessionData} from "./components/session/session_data";
import {LoginModal} from "./components/session/login_modal";


function App() {
  const [sessionData, setSessionData] = useState<SessionData>(initSessionData());
  const [isLoginOpen, setIsLoginOpen] = useState(true); //Show login on start
  
  const handleLogin = () =>{    
    setIsLoginOpen(false);
  }
  

  //**************************************** */
  // Used for bypassing
  
  const bypass_session = initSessionData();
  bypass_session.user = "wdepot";
  bypass_session.pass = "Wdepot"; 
  
  /*
  return (
    <Fragment>      
      <Configurator sessionData={bypass_session} />
    </Fragment>
  );
  */
  
  //****************************************** */
  return (
    <Fragment>
      {isLoginOpen && <LoginModal         
        handleLogin={handleLogin}
        setSessionData={setSessionData} 
      />}
      {sessionData.valid_session && <Configurator sessionData={sessionData} />}
    </Fragment>
  );
  
      
}

export default App;
