import {ConstrainValues} from "./constrain_value";
import {setVisibleConstraint} from "./functions_constrain_set";
import {RenderData} from "../render/render_components/render_data/render_data";

const FLUSH = "Flush Fin";
const NAIL = "Nail On";
const BLACK = "Black Capstock";
const BRONZE = "Bronze";
const ALMOND = "Almond";
const MASON = "Mas Block";

//Constrain values associated with MF2
export const constrainMF2 = (constrainValues: ConstrainValues) =>{
    //Frame Type
    let constrain_type = constrainValues.frame_type;
    setVisibleConstraint(constrain_type, FLUSH, true);
    setVisibleConstraint(constrain_type, NAIL, true);
    
    //Frame Color
    constrain_type = constrainValues.frame_color;
    setVisibleConstraint(constrain_type, BLACK, true);
    setVisibleConstraint(constrain_type, ALMOND, true);

    constrain_type = constrainValues.option;
    setVisibleConstraint(constrain_type, "Auto Lock", true);
}

//Constrain SMF related values
export const constrainSMF = (constrainValues: ConstrainValues) =>{
    //Frame Type
    let constrain_type = constrainValues.frame_type;
    setVisibleConstraint(constrain_type, FLUSH, true);

    //Frame Color
    constrain_type = constrainValues.frame_color;
    setVisibleConstraint(constrain_type, BLACK, true);
    setVisibleConstraint(constrain_type, BRONZE, true);
}

//Constrain TMF related values
export const constrainTMF = (constrainValues: ConstrainValues) =>{
    //Color
    let constrain_type = constrainValues.frame_color;
    setVisibleConstraint(constrain_type, BRONZE, true);
}

//Constrain Aluminium Patio Doors
export const constrainMF2Door = (constrainValues: ConstrainValues, renderData: RenderData) =>{
    //Frame Type
    let constrain_type = constrainValues.frame_type;    
    setVisibleConstraint(constrain_type, NAIL, true);
    setVisibleConstraint(constrain_type, FLUSH, true);
    if(renderData.data.is_callout){
        //********************* This does match with Epicor, commented out at Rufe's request
        //setVisibleConstraint(constrain_type, MASON, true);
    }

    //Frame Color
    constrain_type = constrainValues.frame_color;
    setVisibleConstraint(constrain_type, ALMOND, true);
    setVisibleConstraint(constrain_type, BLACK, true);  
}

//Constrain Vinyl Patio Doors
export const constrainVinylDoor = (constrainValues: ConstrainValues, renderData: RenderData) =>{
    //Frame Type
    let constrain_type = constrainValues.frame_type;
    if(renderData.data.is_callout){
        //********************* This does match with Epicor, commented out at Rufe's request
        //setVisibleConstraint(constrain_type, MASON, true);
        //setVisibleConstraint(constrain_type, FLUSH, true);
    }

    //Frame Color
    constrain_type = constrainValues.frame_color;
    setVisibleConstraint(constrain_type, BRONZE, true);
}

