import Form from "react-bootstrap/Form";
import {useState, useEffect} from "react";
import {RenderData} from "../render/render_components/render_data/render_data";
import {ConstrainValues} from "../constraints/constrain_value";

interface WindowSizeProps{    
    id_item: string;
    DataHandler:(id_item: string, id_object: string, form_object: string, object_value: string, id_key: number) => void;
    renderData: RenderData;
    constrainValues: ConstrainValues;
}

const WindowSizeForm = ({id_item, DataHandler, renderData, constrainValues}: WindowSizeProps) =>{

    const INPUT_MAX_WIDTH = "150px";

    const [selectedOption, setSelectedOption] = useState("");
    const [inputCallout, setInputCallout] = useState("");
    const [inputWidth, setInputWidth] = useState("");
    const [inputHeight, setInputHeight] = useState("");    

    //Handle a text control losing focus
    const handleBlur = (id: string, value: string) =>{        
        processTextValue(id, value);
    }
    //Handle a key down event on a text control
    const handleKeyDown = (id: string, value: string, event: React.KeyboardEvent<HTMLElement>) =>{        
        if(event.key === 'Enter'){
            processTextValue(id, value);
        }
    }
    //Process a text value 
    const processTextValue = (id: string, value: string) =>{
        if(id === "size_callout"){
            //Can't have a decimal callout size
            if(value.indexOf(".") > 0){
                return;
            }           
        }
        
        DataHandler(id_item, "", id, value, -1);
    }

    const handleTextChange = (input_id: string, input_value: string) =>{      

        const value_number = Number(input_value);    
        
        //Check if it's a valid entry
        if(!isNaN(value_number)){
            let valid_value = true;

            //Update the input text with the value that was entered; allow for blanks
            if(valid_value || input_value === ""){
                switch(input_id){
                    case "size_callout":
                        setInputCallout(input_value);
                    break;
                    case "size_width":
                        setInputWidth(input_value);
                    break;
                    case "size_height":
                        setInputHeight(input_value);
                    break;
                }
            }
        }
    }

    useEffect(() =>{
        let inch_width = String(renderData.viewport.inch_width);
        let inch_height = String(renderData.viewport.inch_height);
        let callout = String(renderData.data.callout);        
        
        if(renderData.viewport.inch_width ===0) inch_width = "";
        if(renderData.viewport.inch_height ===0) inch_height = "";
        if(renderData.data.callout <=0) callout = "";
        
        //Set the text box values to their respective input boxes
        setInputCallout(callout);        
        setInputWidth(inch_width);
        setInputHeight(inch_height);
        
        //This is to handle when the form is reset while net size is selected
        if(selectedOption === "callout" && !renderData.data.is_callout){
            handleRadioChange("callout");
        }

    }, [renderData]);

    const handleRadioChange = (id: string) => {
        setSelectedOption(id);
        DataHandler(id_item, "", id, "", -1);
    }

    //Currently only handles if the callout is disabled
    const isDisabled = () =>{
        return constrainValues.control.size_callout.is_disabled;
    }

    return(
        <Form onSubmit={(event) => event.preventDefault()}>        
            <div key="size_select" className="mb-3">
                <Form.Check
                    inline
                    label="Callout"                    
                    name="group_select_size"
                    type="radio"
                    id="callout"
                    onChange={(event) => handleRadioChange(event.target.id)}
                    disabled={isDisabled()}
                />
                <Form.Check
                    inline
                    label="Net Size"
                    name="group_select_size"
                    type="radio"
                    id="net_size"
                    onChange={(event) => handleRadioChange(event.target.id)}
                />            
            </div>        
            {selectedOption === 'callout' && (
                <Form.Group className="mb-3">
                    <Form.Label>Callout</Form.Label>
                    <Form.Control 
                        id="size_callout"
                        type="text" 
                        placeholder="Enter Callout" 
                        value={inputCallout}
                        style={{width: INPUT_MAX_WIDTH}}
                        onChange={(event) => handleTextChange(event.target.id, event.target.value)}
                        onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                        onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)}
                        disabled={isDisabled()}
                    />                        
                </Form.Group>
            )}
            {selectedOption === 'net_size' && ( 
                <Form.Group className="mb-3">
                    <Form.Label>Width</Form.Label>
                    <Form.Control 
                        id="size_width"
                        type="text" 
                        placeholder="Enter Width" 
                        value={inputWidth}
                        style={{width: INPUT_MAX_WIDTH}}
                        onChange={(event) => handleTextChange(event.target.id, event.target.value)}
                        onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                        onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)} 
                    />
                    <Form.Label>Height</Form.Label>
                    <Form.Control 
                        id="size_height"
                        type="text" 
                        placeholder="Enter Height" 
                        value={inputHeight}
                        style={{width: INPUT_MAX_WIDTH}}
                        onChange={(event) => handleTextChange(event.target.id, event.target.value)}
                        onBlur={(event) => handleBlur(event.target.id, event.target.value)}
                        onKeyDown={(event) => handleKeyDown(event.currentTarget.id, event.currentTarget.value, event)} 
                    />
                </Form.Group>       
            )}
        </Form>     
    );
}

export default WindowSizeForm;